<template>
    <div>
        <LiefengContent>
            <template v-slot:title>{{ "个人账单" }}</template>
            <template v-slot:toolsbarLeft></template>
            <template v-slot:contentArea>
                <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="tableSelect"
                ></LiefengTable>

                
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"



export default {
    components: { LiefengContent, LiefengTable  },
    data() {
        return {
            // table表格数据
            talbeColumns: [
                {
                    title: "标题",
                    key: "rechargeTitle",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "操作时间",
                    key: "actionTime",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("div", params.row.actionTime && params.row.actionTime != "" ? this.$core.formatDate(new Date(params.row.actionTime), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "操作金额",
                    key: "actionTime",
                    minWidth: 100,
                    align: "center",

                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: params.row.rechargeType == "2" ? "green" : "red",
                                },
                            },
                            (params.row.rechargeType == "2" ? "-" : "+") +
                                (params.row.rechargeAmount && params.row.rechargeAmount != "" && params.row.rechargeAmount != 0 ? params.row.rechargeAmount / 100 + "元" : 0)
                        )
                    },
                },
            ],
            tableData: [],
            loading: false,
            page: 1,
            total: 0,
            pageSize: 20,
        }
    },
    async created() {
        this.getList()
    },
    methods: {
        // 分页改变接口
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        getList() {
            this.$get("/gateway/api/sycompany/pc/CompanyRechargePC/selectInfoPage", {
                page: this.page,
                pageSize: this.pageSize,
                userId: this.$route.query.userId,
                companyId: this.$route.query.companyId,
                rechargeType: "",
            }).then(res => {
                this.tableData = res.dataList
                this.total = res.maxCount
            })
        },
    },
}
</script>
<style lang="less">
.export-modal {
    /deep/ #modal_contentarea {
        overflow: visible !important;
        height: 270px;
    }
}
</style>